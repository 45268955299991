import React from "react";
import useTabPreferences2 from "../../../hooks/lnd16/useTabPreferences2";
import styles from '../../../styles/lnd24/index.module.scss';

export default ({active, setActive}) => {
    const {doCheck, doOnNext} = useTabPreferences2(() => setActive());

    return (
        <>
            <div className={`${styles.box} ${active ? styles.active : styles.box_hidden}`}>
                <div className={`${styles['banner-box__cap']} ${styles['banner-box__cap_grid']}`}>
                    <div className={styles['title-site_h2-optional']}>Questions</div>
                    <ul className={`${styles['tabs']} ${styles['tabs-value']}`}>
                        <li className={styles.passed}>1</li>
                        <li className={styles.passed}>2</li>
                        <li className={styles.passed}>3</li>
                        <li className={styles.current}>4</li>
                        <li>5</li>
                        <li>6</li>
                        <li>7</li>
                        <li>8</li>
                    </ul>
                </div>
                <div className={`${styles['banner-box__body']} ${styles['banner-box__body_wrapp1']}`}>
                    <p className={styles['box__txt-st']}>Which girls do you prefer?</p>
                    <ul className={`${styles['box__body-check']} ${styles['box__body-check_center']} ${styles['box__body-check_big']}`}>
                        <li>
                            <label className={styles.check}>
                                <input className={`${styles.check__input} ${styles.validate}`} type="radio" minLength="1"
                                       onChange={(e) => {
                                           doCheck('18 - 25 y.o.', e?.target)
                                       }}/>
                                <span className={styles.check__box}></span>
                                18 - 25 y.o.
                            </label>
                        </li>
                        <li>
                            <label className={styles.check}>
                                <input className={`${styles.check__input} ${styles.validate}`} type="radio"
                                       onChange={(e) => {
                                           doCheck('26 - 34 y.o.', e?.target)
                                       }}/>
                                <span className={styles.check__box}></span>
                                26 - 34 y.o.
                            </label>
                        </li>
                        <li>
                            <label className={styles.check}>
                                <input className={`${styles.check__input} ${styles.validate}`} type="radio"
                                       onChange={(e) => {
                                           doCheck('35 - 40 y.o.', e?.target)
                                       }}/>
                                <span className={styles.check__box}></span>
                                35 - 40 y.o.
                            </label>
                        </li>
                        <li>
                            <label className={styles.check}>
                                <input className={`${styles['check__input']} ${styles['validate']}`} type="radio"
                                       onChange={(e) => {
                                           doCheck('41 - 55 y.o.', e?.target)
                                       }}/>
                                <span className={styles.check__box}></span>
                                41 - 55 y.o.
                            </label>
                        </li>
                        <li>
                            <label className={styles.check}>
                                <input className={`${styles.check__input} ${styles.validate}`} type="radio"
                                       onChange={(e) => {
                                           doCheck('56 - 70 y.o.', e?.target)
                                       }}/>
                                <span className={styles.check__box}></span>
                                56 - 70 y.o.
                            </label>
                        </li>
                    </ul>
                    <div className={styles['link-next']}>
                        <button type="button" className={`${styles['btn']} ${styles['btn_bold']} ${styles['btn_lg']} ${styles['btn_min-width']} ${styles['tabs-next']}`}
                                onClick={() => doOnNext()}>CONTINUE
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}