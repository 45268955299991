import {useState} from 'react';

export default function useTabAge(setActive, formData, setFormData) {
    const [inputValid, setInputValid] = useState(true);

    const handleNext = () => {
        if (isValid(formData?.age)) {
            setActive();
        } else {
            setInputValid(false);
        }
    };

    const doSetFormData = (target) => {
        const value = target?.value;

        if (value?.length > 2) {
            target.value = value?.substring(0, 2);
        } else {
            setFormData({...formData, ...{age: value}});
        }
    };

    function isValid(age) {
        if (age?.length === 0) {
            return false;
        }

        const num = parseInt(age);
        return num >= 24 && num <= 99
    }

    return {handleNext, doSetFormData, inputValid}
}