import React from "react";
import styles from '../../../styles/lnd24/index.module.scss';

export default ({active = false, setActive, formData, setFormData}) => {
    return (
        <>
            <div className={`${styles.box} ${active ? styles.active : styles.box_hidden}`}>
                <div className={`${styles['banner-box__cap']} ${styles['banner-box__cap_grid']}`}>
                    <div className={styles['title-site_h2-optional']}>Questions</div>
                    <ul className={`${styles['tabs']} ${styles['tabs-value']}`}>
                        <li className={styles.passed}>1</li>
                        <li className={styles.current}>2</li>
                        <li>3</li>
                        <li>4</li>
                        <li>5</li>
                        <li>6</li>
                        <li>7</li>
                        <li>8</li>
                    </ul>
                </div>
                <div className={`${styles['banner-box__body']} ${styles['banner-box__body_wrapp1']}`}>
                    <p className={styles['box__txt-st']}><b>Are you okay</b> with the <b>Woman</b> making the first move?</p>
                    <div className={styles['group-btn']}>
                        <button type="button" className={`${styles['btn']} ${styles['btn_lg']} ${styles['btn_bold']} ${styles['tabs-next-novalidate']}`}
                                onClick={() => setActive()}>YES
                        </button>
                        <button type="button" className={`${styles['btn']} ${styles['btn_danger']} ${styles['btn_lg']} ${styles['btn_bold']} ${styles['tabs-next-novalidate']}`}
                                onClick={() => setActive()}>NO
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}