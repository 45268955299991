import {useHistory} from "react-router-dom";
import * as UTM_TAGS from '../../constants/utmTags';
import * as SOCIAL from '../../constants/social';
import LoginApi from "../../services/api/LoginApi";
import SocialApi from "../../services/api/SocialApi";

export default function useSocialFacebook() {
    const history = useHistory();

    const authenticate = (response) => {
        if (response?.status === 'unknown') {
            const search = window?.location?.search?.substring()?.replace(`&${UTM_TAGS.QUICK_REG}=${SOCIAL.FB}`, '');
            history.push(window?.location?.pathname + search);
            return;
        }

        new SocialApi()
            .postFacebookAuth(response)
            .then(res => {
                if (res?.status && res?.token) new LoginApi().tokenLogin(res?.token);
                else alert('Auth Error');
            });
    };

    return {authenticate}
}