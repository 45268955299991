//LND24
import bg_24 from "../lnd24/bg.jpg";
import image1_24 from "../lnd24/image1.jpg";


import card1 from "./card1.svg";
import card2 from "./card2.svg";
import card3 from "./card3.svg";
import card4 from "./card4.svg";
import card5 from "./card5.svg";
import card6 from "./card6.svg";
import card7 from "./card7.svg";
import card8 from "./card8.svg";
import card9 from "./card9.svg";
import close from "./close.svg";
import fb from "./fb.png";
import gg from "./gg.png";
import logo from "./logo.png";
import logoMySpecialDates from "./logoMSD.svg";
import loading from "./loading.gif";

export default {
    //LND24
    bg_24, image1_24,

    card1, card2, card3, card4, card5, card6, card7, card8, card9, close,
    fb, gg, logo, loading, logoMySpecialDates
}