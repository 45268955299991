import React, {useEffect, useState} from 'react';
// import Footer from '../../components/lnd16/Footer';
import Header from '../../components/lnd16/Header';
import Helmet from '../../components/common/Helmet';
import MainBlock from '../../components/lnd24/MainBlock';
import useInitBlock from "../../hooks/lnd16/useInitBlock";
import useCheckValidToken from "../../hooks/useCheckValidToken";

export default () => {
    useCheckValidToken();
    useInitBlock();

    const initSiteData = {
        helmetTitle: '',
        headerLogo: '',
        mainTitle: '',
        image: '',
        background: '',
        title: '',
        description: '',
        btnTitle: '',
        subTitle: '',
        subDescription: '',
        termTitle: '',
        supportEmail: '',
        domain: '',
        supportDomain: '',
    };

    const [siteData, setSiteData] = useState(initSiteData);

    useEffect(() => {
        const mainBlock = document.querySelector('#root');

        setSiteData({
            helmetTitle: mainBlock?.dataset?.helmettitle,// ?? 'SOFIA',
            headerLogo: mainBlock?.dataset?.headerlogo,// ?? IMAGES.default.logo,
            mainTitle: mainBlock?.dataset?.maintitle,// ?? 'True Love is a Click Away!',
            image: mainBlock?.dataset?.image,// ?? IMAGES.default.image1_24,
            background: mainBlock?.dataset?.background,// ?? IMAGES.default.image1_24,
            title: mainBlock?.dataset?.title,// ?? 'Ukranian/Russian Dating Site',
            description: mainBlock?.dataset?.description,// ?? 'Chat With Single Ukranian/Russian Women Now',
            btnTitle: mainBlock?.dataset?.btntitle,// ?? 'OK',
            subTitle: mainBlock?.dataset?.subtitle,// ?? 'FREE REGISTRATION',
            subDescription: mainBlock?.dataset?.subdescription,// ?? 'no credit card required',
            termTitle: mainBlock?.dataset?.termtitle,// ?? 'no credit card required',
            supportEmail: mainBlock?.dataset?.supportemail,// ?? 'no credit card required',
            domain: mainBlock?.dataset?.domain,// ?? 'no credit card required',
            supportDomain: mainBlock?.dataset?.supportdomain,// ?? 'no credit card required',

        });
    }, []);

    return (
        siteData?.title?.length
            ? <>
                <div id="wrapper">
                    <Helmet {...siteData}/>
                    <Header {...siteData}/>
                    <MainBlock {...{...siteData}}/>
                    {/*<Footer/>*/}
                </div>
            </>
            : <div/>
    );
};
