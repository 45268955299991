import {useHistory} from "react-router-dom";
import * as UTM_TAGS from '../../constants/utmTags';
import * as SOCIAL from '../../constants/social';
import LoginApi from "../../services/api/LoginApi";
import SocialApi from "../../services/api/SocialApi";

export default function useSocialGoogle() {
    const history = useHistory();

    const responseGoogle = (response) => {
        if (response && response.error) {
            const search = window.location.search.substring().replace(`&${UTM_TAGS.QUICK_REG}=${SOCIAL.GMAIL}`, '');
            history.push(window.location.pathname + search);
            return;
        }

        let googleData = {
            name: response.profileObj.name,
            email: response.profileObj.email,
            userID: response.googleId,
            tail: window.location.search,
        };

        new SocialApi()
            .postGoogleAuth(googleData)
            .then(res => {
                if (res && res.status && res.token) new LoginApi().tokenLogin(res.token);
                else alert('Auth Error');
            });
    };

    return {responseGoogle}
}