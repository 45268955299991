import axios from 'axios';
import {getUrlParameter} from "../methods";
import * as SOCIAL from '../../constants/social';

export default class PageApi {
    postGoogleAuth(data) {
        let click_id = '';
        const utm_netw = getUrlParameter('utm_netw');

        if (utm_netw === 'MGID') click_id = getUrlParameter('tid');
        else click_id = getUrlParameter('msclkid');

        data.click_id = click_id;

        return axios
            .post(SOCIAL.PATH_GOOGLE_AUTH, data)
            .then(res => res && res.data);
    }

    postFacebookAuth(data) {
        let click_id = '';
        const utm_netw = getUrlParameter('utm_netw');

        if (utm_netw === 'MGID') click_id = getUrlParameter('tid');
        else click_id = getUrlParameter('msclkid');

        data.click_id = click_id;
        return axios
            .post(SOCIAL.PATH_FB_AUTH, data)
            .then(res => res && res.data);
    }
}