import React from "react";
import {Helmet} from "react-helmet";

export default ({helmetTitle}) => {
    return(
        <Helmet>
            <title>{helmetTitle}</title>
        </Helmet>
    )
}
