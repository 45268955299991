import React from "react";
import TabAge from '../../../lnd16/TabAge';
import TabName from '../../../lnd16/TabName';
import TabEmail from '../../../lnd16/TabEmail';
import TabGender from '../../../lnd16/TabGender';
import TabRelationship from '../../../lnd16/TabRelationship'
import TabRelationship2 from '../../../lnd16/TabRelationship2'
import TabPreferences from '../../../lnd16/TabPreferences'
import TabPreferences2 from '../../../lnd16/TabPreferences2'
import TabPassword from '../../../lnd16/TabPassword';
import Social from '../../../lnd16/Social';
import * as IMAGES from "../../../../img/lnd16";
import LoaderModal from '../../../common/Modals/LoaderModal';
import useSocialFacebook from "../../../../hooks/social/useSocialFacebook";
import useSocialGoogle from "../../../../hooks/social/useSocialGoogle";
import useInitBlockForm from "../../../../hooks/lnd16/useInitBlockForm";
import styles from '../../../../styles/lnd24/index.module.scss';

export default (props) => {
    const initStateFormData = {gender: '', user_real_name: '', age: '', password: '', email: ''};
    const {authenticate} = useSocialFacebook();
    const {responseGoogle} = useSocialGoogle();
    const {
        active,
        doSetActive,
        saveHistory,
        formData,
        setFormData,
        onSubmit,
        saveAge,
        saveIndexedHistory,
        modalIsActiveLoader
    } = useInitBlockForm(initStateFormData);

    return (
        <>
            <div className={styles['bl-tabs']}>
                <div className={styles['tabs-info']} id="formTabs">
                    <TabGender
                        active={active === 1}
                        setActive={() => doSetActive(2)}
                        saveHistory={saveHistory}
                        setFormData={setFormData}
                        formData={formData}
                        data={props}
                    />
                    <TabRelationship
                        active={active === 2}
                        setActive={() => doSetActive(3)}
                        setFormData={setFormData}
                        formData={formData}
                    />
                    <TabRelationship2
                        active={active === 3}
                        setActive={() => doSetActive(4)}
                        setFormData={setFormData}
                        formData={formData}
                    />
                    <TabPreferences
                        active={active === 4}
                        setActive={() => doSetActive(5)}
                        setFormData={setFormData}
                        formData={formData}
                    />
                    <TabPreferences2
                        active={active === 5}
                        setActive={() => doSetActive(6)}
                        setFormData={setFormData}
                        formData={formData}
                    />
                    <TabAge
                        active={active === 6}
                        setActive={() => saveAge()}
                        setFormData={setFormData}
                        formData={formData}
                    />
                    <TabName
                        active={active === 7}
                        setActive={() => doSetActive(8)}
                        setFormData={setFormData}
                        formData={formData}
                    />
                    <TabEmail
                        active={active === 8}
                        setActive={() => doSetActive(9)}
                        setFormData={setFormData}
                        formData={formData}
                    />
                    <TabPassword
                        active={active === 9}
                        setActive={() => onSubmit()}
                        setFormData={setFormData}
                        formData={formData}
                    />
                </div>

                {window?.location?.href?.includes('myspecialdates')
                    ? <div />
                    : <Social
                        IMAGES={IMAGES}
                        active={active > 7}
                        saveParam={saveIndexedHistory}
                        responseGoogle={responseGoogle}
                        authenticate={authenticate}
                    />
                }

                <div className={`${active > 1 ? styles.gap : ''}`}/>
            </div>
            <LoaderModal modalIsActive={modalIsActiveLoader}/>
        </>
    )
}