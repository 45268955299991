import React, {useState} from 'react';
import Form from './Form';
import TermModal from '../../common/Modals/TermsModal';
import useInitTerm from "../../../hooks/useInitTerm";
import styles from '../../../styles/lnd24/index.module.scss';

export default (props) => {
    const [cookiesBannerActive, setCookiesBannerActive] = useState(true);
    const initTerm = useInitTerm();

    return (
        <div id="middle" className={styles['middle-box']}>
            <div className={styles['middle-box__wrapp-middle']}>
                <div className={styles['banner-box']}>
                    <Form {...props}/>
                </div>
            </div>
            <div className={styles['carousel-bg-wrapp']} style={{backgroundImage: `url(${props?.background})`}}>
            </div>
            <div className={`${styles['banner-cookie']} ${cookiesBannerActive ? styles['open-popup_opened'] : ''} ${styles['open-popup-cookies']}`}>
                <span className={styles['banner-cookie__close']} aria-label="close"
                      onClick={() => setCookiesBannerActive(false)}/>
                <p>By continuing to use our site you are agreeing to our cookie policy</p>
                <mya onClick={() => initTerm.openTermModal('cookie-policy')}>Learn more</mya>
            </div>
            <div className={`${styles.copy} ${styles.copy_wrapp} ${styles['open-popup_opened']}`}>
                <div className={`${styles['container-full']} ${styles.text_center}`}>
                    <div className={styles.copy__txt}>{props?.termTitle}</div>
                    <ul className={styles['copy__list-links']}>
                        <li>
                            <mya onClick={() => initTerm.openTermModal('terms-and-conditions')}>Terms and Conditions
                            </mya>
                        </li>
                        <li>
                            <mya onClick={() => initTerm.openTermModal('privacy-policy')}>Privacy Policy</mya>
                        </li>
                    </ul>
                </div>
            </div>
            <TermModal {...initTerm} {...props}/>
        </div>
    )
}