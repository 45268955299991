import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Lnd24 from "../pages/lnd24";

export const Router = () => {
        return (
            <main className="content">
                <Switch>
                    <Route exact path={`/`} component={Lnd24}/>
                    <Route exact path={`/:block`} component={Lnd24}/>
                </Switch>
            </main>
        )
};
