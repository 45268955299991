import React from "react";
import {GoogleLogin} from "react-google-login";
//import FacebookAuth from "react-facebook-auth";
import img from "../../../img/lnd16";
import styles from '../../../styles/lnd24/index.module.scss';

export default ({saveParam, IMAGES, active, responseGoogle, authenticate}) => {
    // const MyFacebookButton = ({onClick}) => (
        // <div className="socAuth_btn" onClick={onClick}>
        //     <img src={img.images["fb.png"]} alt={'facebook login'} style={{ cursor: 'pointer'}}/>
        //     <span>Sign up with Facebook</span>
        // </div>
        // <li className="bl-social__item">
        //     <mya className="social-link" onClick={() => {
        //         saveParam('utm_quick_reg', 'fb', 3);
        //         onClick();
        //     }}>
        //         <span className="social-link__icon">
        //           <img src={IMAGES.default.fb} alt="facebook"/>
        //         </span>
        //         <span className="social-link__txt">Sign up with <b>Facebook</b></span>
        //     </mya>
        // </li>
    // );

    return (
        <ul className={`${styles['bl-social']} ${active ? '' : styles.disabled}`}>
            <GoogleLogin
                clientId="760147230750-m3tb84fri39ij1552hpq37m351iu23ta.apps.googleusercontent.com"
                buttonText="Login"
                render={renderProps => (
                    // <div className="socAuth_btn" onClick={renderProps.onClick}>
                    //     <img src={img.images['gg.png']} alt={'google login'} />
                    //     <span>Sign up with Google</span>
                    // </div>
                    <li className={styles['bl-social__item']}>
                        <mya className={`${window.innerWidth >= 768 ? styles['social-link'] : styles['block-hidden']}`} onClick={() => {
                            saveParam('utm_quick_reg', 'gmail', 3);
                            //history.push(window.location.pathname + '?' + firstLoad + '&utm_quick_reg=gmail');
                            renderProps.onClick();
                        }}>
                                    <span className={styles['social-link__icon']}>
                                      <img src={IMAGES.default.gg} alt="google"/>
                                    </span>
                            <span className={styles['social-link__txt']}>Sign up with <b>Google</b></span>
                        </mya>
                        <div className={`${window.innerWidth < 768 ? '' : styles['block-hidden']}`}>
                            <span className={styles['social-link__txt']}>Sign up with
                                <mya className={styles['social-link-small']} onClick={() => {
                                    saveParam('utm_quick_reg', 'gmail', 3);
                                    //history.push(window.location.pathname + '?' + firstLoad + '&utm_quick_reg=gmail');
                                    renderProps.onClick();
                                }}>
                            <span className={styles['social-link__icon']}>
                                      <img src={IMAGES.default.gg} alt="google"/>
                                    </span>
                        </mya>
                            </span>
                        </div>
                    </li>
                )}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
            />
            {/*{
                window.innerWidth > 1024
                && <FacebookAuth
                    appId="555820671748103"
                    callback={authenticate}
                    component={MyFacebookButton}
                />
            }*/}
        </ul>
    )
}