import React from 'react';
import styles from '../../../styles/lnd24/index.module.scss';

export default ({headerLogo}) => {
    return (
        <header id="header" className={styles.header}>
            <div className={styles['container-full']}>
                <div className={styles.header__inner}>
                    <div className={styles.logo}>
                        <mya>
                            <img src={headerLogo} alt="logotype"/>
                        </mya>
                    </div>
                </div>
            </div>
        </header>
    )
}