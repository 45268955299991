import {useState} from 'react';

export default function useTabPreferences(setActive) {
    const initStateCheckedData = {data: []};

    const [checked, setChecked] = useState(initStateCheckedData);

    const doCheck = (key, target) => {
        const isChecked = target.checked;

        if (checked?.data?.length >= 3 && isChecked === true) {
            target.checked = false;
            return;
        }

        if (isChecked) {
            if (!checked?.data?.includes(key)) {
                setChecked({...checked, ...{data: [...checked?.data, key]}})
            }
        } else {
            const filtered = checked?.data?.filter(k => k !== key);
            setChecked({...checked, ...{data: filtered}});
        }
    };

    const doOnNext = () => setActive();

    return{doCheck, doOnNext}
}