import React from 'react';

import styles from '../styles/index.module.css';

import * as MENU from "../constants";
import * as IMAGES from '../img';
import Term from './Term';
import Cookie from './Cookie';
import Privacy from './Privacy';

export default ({pageName = null, close, data}) => {
    const checkTermPage = () => {
        return MENU.ARRAY_STATIC_PAGE.includes(pageName);
    };

    const switchTermPage = () => {
        switch (pageName) {
            case MENU.COOKIE_STATIC_PAGE:
                return <Cookie {...data}/>;
            case MENU.PRIVACY_STATIC_PAGE:
                return <Privacy {...data}/>;
            case MENU.TERM_STATIC_PAGE:
                return <Term {...data}/>;
            case MENU.REFUND_STATIC_PAGE:
            default:
                return <div/>;
        }
    };

    const switchTermTitle = () => {
        switch (pageName) {
            case MENU.COOKIE_STATIC_PAGE:
                return 'Cookie Policy';
            case MENU.PRIVACY_STATIC_PAGE:
                return 'Privacy Policy';
            case MENU.TERM_STATIC_PAGE:
                return 'Terms and Conditions';
            case MENU.REFUND_STATIC_PAGE:
            default:
                return '';
        }
    };

    return (
        checkTermPage()
            ? <div className={styles.term_popup_wrap}>
                <div className={styles.term_popup_close}>
                    <img src={'https://common-f.s3.us-east-2.amazonaws.com/google/static/media/close.f58d10aa.svg'} alt="" onClick={close}/>
                </div>
                <div className={styles.term_popup_title}>{switchTermTitle()}</div>
                <div className={styles.term_popup_content}>
                    {switchTermPage()}
                </div>
            </div>
            : <div/>

    )
}
