import {useState} from "react";
import {checkEmail} from "../../services/methods";
import LoginApi from "../../services/api/LoginApi";

export default function useTabEmail(formData, setActive) {
    const [inputValid, setInputValid] = useState(true);
    const [emailUnique, setEmailUnique] = useState(true);

    const handleNext = async () => {
        if (formData?.email.trim() === '') {
            setInputValid(false);
            return false;
        }

        if (!checkEmail(formData?.email)) {
            setInputValid(false);
            return false;
        }

        const res = await new LoginApi().checkEmailAPI(formData?.email);
        const isEmailInUse = res?.status;

        if (isEmailInUse) {
            setEmailUnique(false);
            return false;
        }

        setActive();
    };

    return {handleNext, inputValid, emailUnique}
}