import {useState} from 'react';

export default function useTabPreferences2(setActive) {
    const [checked, setChecked] = useState({key: '', activeTarget: null});

    const doCheck = (name, target) => {
        if (target?.checked) {
            const oldKey = checked?.key;
            const oldTarget = checked?.activeTarget;
            if (oldKey !== name) {
                setChecked({...checked, ...{key: name, activeTarget: target}});

                if (oldTarget !== null) {
                    oldTarget.checked = false;
                }
            }
        }
    };

    const doOnNext = () => setActive();

    return{doCheck, doOnNext}
}