import {useState} from "react";

export default function useTabPassword(formData, setActive) {
    const [passValid, setPassValid] = useState(true);

    const handleNext = () => {
        if (formData?.password?.length < 6) {
            setPassValid(false);
            return false;
            // eslint-disable-next-line no-useless-escape
        }
        setActive();
    };

    return {handleNext, passValid}
}