import React from "react";
import useTabAge from "../../../hooks/lnd16/useTabAge";
import styles from '../../../styles/lnd24/index.module.scss';

export default ({active = false, setActive, formData, setFormData}) => {
    const {handleNext, doSetFormData, inputValid} = useTabAge(() => setActive(), formData, setFormData);

    return (
        <>
            <div className={`${styles.box} ${active ? styles.active : styles.box_hidden}`}>
                <div className={`${styles['banner-box__cap']} ${styles['banner-box__cap_grid']}`}>
                    <div className={styles['title-site_h2-optional']}>Registration</div>
                    <ul className={`${styles.tabs} ${styles['tabs-value']}`}>
                        <li className={styles.passed}>1</li>
                        <li className={styles.passed}>2</li>
                        <li className={styles.passed}>3</li>
                        <li className={styles.passed}>4</li>
                        <li className={styles.current}>5</li>
                        <li>6</li>
                        <li>7</li>
                        <li>8</li>
                    </ul>
                </div>

                <div className={`${styles['banner-box__body']} ${styles['banner-box__body_wrapp1']}`}>
                    <div className={styles.age_gap}/>
                    <div
                        className={`${styles['form-group']} ${styles['form-group_sm-push']} ${styles['form-group_year']}`}>
                        <div className={styles['box-preview__desc']}>Understanding your real age will help to find the
                            best matches for you
                        </div>
                        <input className={`${styles['form-group__field']} ${styles.validate}`} type="number" min="24"
                               max="99" placeholder="Enter your age"
                               onKeyUp={(e) => {
                                   if (e.key === 'Enter') {
                                       handleNext();
                                   }
                               }}
                               onChange={(e) => doSetFormData(e.target)}/>
                    </div>
                    <div
                        className={`${styles['input-label__suggestion']} ${styles.error_color} ${styles['margin-top__10px']} ${!inputValid ? styles.error_color : styles.box_hidden}`}>Your
                        age should be more than 24
                    </div>
                    <div className={styles['link-next']}>
                        <button type="button"
                                className={`${styles['btn']} ${styles['btn_bold']} ${styles['btn_lg']} ${styles['btn_min-width']} ${styles['tabs-next']}`}
                                onClick={handleNext}>NEXT
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}