import React from "react";
import useTabPassword from "../../../hooks/lnd16/useTabPassword";
import styles from '../../../styles/lnd24/index.module.scss';

export default ({active = false, setActive, formData, setFormData}) => {
    const {handleNext, passValid} = useTabPassword(formData, setActive);

    return (
        <>
            <div className={`${styles.box} ${active ? styles.active : styles.box_hidden}`}>
                <div className={`${styles['banner-box__cap']} ${styles['banner-box__cap_grid']}`}>
                    <div className={styles['title-site_h2-optional']}>Final step</div>
                    <ul className={`${styles['tabs']} ${styles['tabs-value']}`}>
                        <li className={styles.passed}>1</li>
                        <li className={styles.passed}>2</li>
                        <li className={styles.passed}>3</li>
                        <li className={styles.passed}>4</li>
                        <li className={styles.passed}>5</li>
                        <li className={styles.passed}>6</li>
                        <li className={styles.passed}>7</li>
                        <li className={styles.passed}>8</li>
                    </ul>
                </div>

                <div className={`${styles['banner-box__body']} ${styles['banner-box__body_wrapp1']}`}>
                    <div className={`${styles['form-group']} ${styles['form-group_sm']}`}>
                        <p className={styles['box__txt-st']}>Enter your password</p>
                        <input id="password"
                               className={`${styles['form-group__field']} ${styles['form-group-name__field']} ${styles['validate']}`}
                               type="text"
                               placeholder="Your password"
                               value={formData?.password}
                               onKeyUp={(e) => {
                                   if (e.key === 'Enter') {
                                       handleNext();
                                   }
                               }}
                               onChange={(e) => {
                                   setFormData({...formData, ...{password: e?.target?.value}});
                               }}/>
                    </div>
                    <div className={`${styles['input-label__suggestion']} ${passValid ? '' : styles.error_color}`}>Minimum 6 characters</div>
                    <div className={styles['link-next']}>
                        <button type="button" className={`${styles['btn']} ${styles['btn_bold']} ${styles['btn_lg']} ${styles['btn_min-width__no-margin']} ${styles['tabs-next']}`}
                                onClick={handleNext}>Find your matches
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}